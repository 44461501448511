import type { FunctionComponent } from "react"

import classnames from "classnames"

import { NewJTLogoFY25 } from "@/modules/headerFooterFO/Brand/NewJTLogoFY25"

import styles from "./Brand.module.css"
import headerStyles from "@/modules/headerFooterFO/Header/Header.module.css"

type JTLogoProps = {
  from: "footer" | "header"
  isLogoFiscalYear25Enabled: boolean
  JTLogoClassName?: string
}

export const JTLogo: FunctionComponent<JTLogoProps> = ({ from, isLogoFiscalYear25Enabled, JTLogoClassName }) => {
  return isLogoFiscalYear25Enabled ? (
    <NewJTLogoFY25 from={from} JTLogoClassName={JTLogoClassName} />
  ) : (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 206 36"
      className={classnames(
        { [styles["app-Footer__logo"]]: from === "footer" },
        { [headerStyles.hdr__logo]: from === "header" }
      )}
    >
      <title>JobTeaser</title>
      <desc>JobTeaser Logo</desc>
      <path
        className={classnames(
          { [styles["app-Footer__logoIcon"]]: from === "footer" },
          { [headerStyles.hdr__logoIcon]: from === "header" }
        )}
        d="m0 15.194 25.77 20.717-12.887-20.717H0Zm31.08 6.451L20.88 4.934H10.138l10.387 16.725 10.555-.014ZM32.2 0H21.565l5.291 8.997L32.2 0Z"
        clipRule="evenodd"
      />
      <path
        className={classnames(
          { [styles["app-Footer__logoText"]]: from === "footer" },
          { [headerStyles.hdr__logoText]: from === "header" }
        )}
        d="M71.52 23.13c-2.998 0-4.96-2.166-4.96-5.14 0-2.976 1.962-5.14 4.96-5.14 2.999 0 4.96 2.164 4.96 5.14 0 2.974-1.961 5.14-4.96 5.14Zm0-13.526c-4.983 0-8.612 3.27-8.612 8.387 0 5.118 3.63 8.387 8.613 8.387 4.98 0 8.61-3.269 8.61-8.387 0-5.117-3.63-8.387-8.61-8.387Zm21.977 13.394H90.34v-3.63H93c1.15 0 2.84.248 2.84 1.758 0 1.399-1.24 1.872-2.344 1.872Zm-3.156-10.01h2.389c1.105 0 2.367.34 2.367 1.669 0 1.284-1.127 1.736-2.21 1.736h-2.546v-3.406Zm5.523 4.644v-.046c1.646-.517 2.75-1.736 2.75-3.516 0-3.471-3.044-4.057-5.839-4.057h-5.951v15.96h6.537c2.842 0 5.997-1.103 5.997-4.441 0-2.073-1.398-3.628-3.494-3.9Zm31.24 1.846h6.922v-3.247h-6.922v-2.977h7.327V10.01h-10.843v15.96h11.249v-3.246h-7.733v-3.246Zm35.761-4.981c0-1.194 1.376-1.646 2.367-1.646.858 0 1.984.34 2.548 1.06l2.413-2.639c-1.353-1.194-3.089-1.668-4.893-1.668-3.134 0-6.086 1.782-6.086 5.186 0 5.32 7.485 3.742 7.485 6.672 0 1.128-1.287 1.67-2.436 1.67a3.916 3.916 0 0 1-3.067-1.51l-2.502 2.75c1.511 1.396 3.315 2.006 5.368 2.006 3.289 0 6.153-1.692 6.153-5.253 0-5.591-7.35-3.72-7.35-6.628Zm17.612 4.981h6.921v-3.247h-6.921v-2.977h7.326V10.01H176.96v15.96h11.248v-3.246h-7.731v-3.246Zm-74.912-6.358h4.372v12.85h3.699V13.12h4.372v-3.11h-12.443v3.11Zm96.116 6.11c2.254-.406 3.449-2.143 3.449-4.374 0-3.72-2.75-4.846-6.019-4.846h-5.764l1.508 3.014h1.596v-.04h2.299c1.285 0 2.728.249 2.728 1.85 0 1.736-1.668 1.78-2.955 1.78h-1.872l4.871 9.357h4.217l-4.058-6.741Zm-148.541.76c0 1.168-.022 2.95-1.63 2.95-.747 0-1.452-.617-1.539-1.365l-3.148.77c.508 2.487 2.312 3.632 4.777 3.632 4.225 0 4.973-2.73 4.973-5.613V9.998h-3.432v9.992Zm89.663-10.02 4.914 9.638h-5.914v3.146h7.554L151 25.971h4.312L147.03 9.97h-4.227Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
