import type { FunctionComponent } from "react"

import classnames from "classnames"

import styles from "./Brand.module.css"

type JTLogoProps = {
  from: "footer" | "header"
  JTLogoClassName?: string
}

export const NewJTLogoFY25: FunctionComponent<JTLogoProps> = ({ from, JTLogoClassName }) => {
  const logoTeasyClassName = classnames({
    [styles["app-Brand__logoTeasyBlack"]]: from === "footer",
    [styles["app-Brand__logoTeasyGreen"]]: from === "header",
  })

  const backgroundClassName = classnames({
    [styles["app-Brand__logoTeasy___BackgroundWhite"]]: from === "footer",
    [styles["app-Brand__logoTeasy___BackgroundBlack"]]: from === "header",
  })

  return (
    <svg
      viewBox="0 0 168 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(JTLogoClassName, {
        [styles["app-Footer__JTlogo"]]: from === "footer",
        [styles["app-Header__JTlogoBlack"]]: from === "header",
      })}
    >
      {/* Teasy Logo */}
      <path
        className={backgroundClassName}
        d="M2.39613 5.2929C2.39613 3.52023 3.83433 2.08203 5.607 2.08203H27.0212C28.7938 2.08203 30.232 3.52023 30.232 5.2929V26.7071C30.232 28.4797 28.7938 29.9179 27.0212 29.9179H5.61536C3.84269 29.9179 2.40449 28.4797 2.40449 26.7071V5.2929H2.39613Z"
      />
      <path
        className={logoTeasyClassName}
        d="M7.62649 15.4217C7.42581 15.4217 7.34219 15.6725 7.4927 15.7896L19.4749 25.372C19.6589 25.5226 19.9097 25.2968 19.7843 25.0961L13.7974 15.5137C13.7556 15.4551 13.6887 15.4133 13.6218 15.4133L7.62649 15.4217Z"
      />
      <path
        className={logoTeasyClassName}
        d="M23.6905 18.7268C23.8577 18.7268 23.958 18.5512 23.8661 18.4091L18.8156 10.1728C18.7738 10.1143 18.7069 10.0725 18.64 10.0725H13.5478C13.3806 10.0725 13.2802 10.2481 13.3722 10.3903L18.5146 18.6265C18.5564 18.685 18.6233 18.7268 18.6902 18.7268H23.6905Z"
      />
      <path
        className={logoTeasyClassName}
        d="M21.7186 11.8635C21.8022 11.9973 21.9945 11.9973 22.0781 11.8635L24.4863 7.82482C24.5699 7.68267 24.4695 7.50708 24.3023 7.50708H19.5027C19.3439 7.50708 19.2435 7.68267 19.3188 7.82482L21.7186 11.8635Z"
      />
      {/* Jobteaser Text */}
      <path d="M48.7467 10.3079H51.5713L49.9378 17.8272C49.3167 20.7206 47.479 22.285 44.8075 22.285C42.2212 22.285 40.9705 20.7206 41.5746 17.9618H44.3992C44.1099 19.3076 44.4672 19.8879 45.3265 19.8879C46.1178 19.8879 46.8069 19.2907 47.0962 17.9618L48.7467 10.3079Z" />
      <path d="M61.4999 10.0471C65.473 10.0471 67.064 12.7891 66.4599 16.1703C65.8559 19.5431 63.261 22.2934 59.2964 22.2934C55.3232 22.2934 53.7493 19.5515 54.3533 16.1703C54.9574 12.7891 57.5352 10.0471 61.4999 10.0471ZM59.7388 19.8879C61.8062 19.8879 63.2525 18.2225 63.6438 16.1619C64.0182 14.1012 63.1419 12.4358 61.0745 12.4358C59.0071 12.4358 57.5608 14.1012 57.1864 16.1619C56.7951 18.231 57.6629 19.8879 59.7388 19.8879Z" />
      <path d="M78.9324 15.548C78.7282 15.6321 78.6601 15.7162 78.6431 15.8676C78.6091 16.0863 78.7282 16.1704 78.8473 16.1872C80.2936 16.5069 80.7445 17.5162 80.5488 18.6685C80.1915 20.5946 78.6346 22.0244 76.2099 22.0244H69.5142L72.041 10.3164H77.3584C79.9448 10.3164 81.3486 11.3341 80.9572 13.4285C80.7615 14.4378 79.9193 15.1527 78.9324 15.548ZM74.3552 12.6378L73.8702 14.9004H76.2779C77.3074 14.9004 77.9455 14.4209 78.0816 13.706C78.2177 12.9743 77.7923 12.6378 76.7714 12.6378H74.3552ZM75.7845 19.6946C76.9245 19.6946 77.6051 19.2488 77.7668 18.433C77.9029 17.6676 77.3159 17.1713 76.1673 17.1713H73.3768L72.8408 19.703H75.7845V19.6946Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1181 10.3499H85.8917L85.3727 12.7386L90.5285 12.747C90.7071 12.747 90.8177 12.9404 90.7242 13.1002L88.0527 17.4571L87.0658 22.0579H89.8904L91.8897 12.747H95.5906L96.1181 10.3499Z"
      />
      <path d="M108.922 12.7051H102.865L102.363 15.0349H107.34L106.855 17.2975H101.878L101.376 19.6273H107.434L106.932 22.0244H98.0494L100.576 10.3164H109.458L108.922 12.7051Z" />
      <path d="M120.11 22.0159L119.804 19.4842H115.04L113.644 22.0159H110.684L117.515 10.3079H121.284L123.071 22.0159H120.11ZM116.273 17.2301H119.54L119.14 13.7563C119.072 13.3274 119.157 12.8564 119.259 12.3433C119.293 12.1751 119.225 12.0405 119.021 12.0405C118.817 12.0405 118.732 12.1751 118.698 12.3433C118.562 12.8564 118.426 13.3274 118.196 13.7563L116.273 17.2301Z" />
      <path d="M133.229 14.5889C136.207 14.9254 137.977 15.8001 137.5 18.4664C137.134 20.527 135.126 22.2933 131.698 22.2933C128.201 22.2933 126.95 20.199 127.393 17.9028H130.183C129.979 18.9374 130.32 20.0308 132.098 20.0308C133.799 20.0308 134.539 19.2822 134.633 18.6346C134.769 17.9365 134.318 17.5243 132.583 17.2888C129.018 16.8262 128.193 15.6066 128.55 13.546C128.899 11.5862 130.856 10.0554 133.935 10.0554C137.151 10.0554 138.155 11.9311 137.891 13.916H135.169C135.305 13.1506 134.965 12.318 133.501 12.318C132.038 12.318 131.485 12.9488 131.4 13.445C131.315 13.8908 131.434 14.3871 133.229 14.5889Z" />
      <path d="M151.98 12.7051H145.923L145.421 15.0349H150.398L149.913 17.2975H144.936L144.434 19.6273H150.492L149.99 22.0244H141.107L143.634 10.3164H152.516L151.98 12.7051Z" />
      <path d="M154.694 21.8731L157.221 10.165H162.368C165.695 10.165 166.69 11.6958 166.325 13.7565C166.103 15.0013 164.861 16.4312 163.058 16.6667C162.922 16.6835 162.768 16.7508 162.734 16.9695C162.683 17.1882 162.819 17.2555 162.99 17.2723C164.623 17.4405 164.87 18.1891 164.955 18.7526L165.389 21.8815H162.19L162.122 19.5685C162.105 18.3236 161.722 17.8863 159.918 17.8863H158.37L157.511 21.8815H154.694V21.8731ZM158.872 15.6153H161.058C162.283 15.6153 163.262 15.0182 163.432 14.0845C163.636 13.0332 163.075 12.5537 161.781 12.5537H159.527L158.872 15.6153Z" />
    </svg>
  )
}
